<template>
  <div :class="chartTable ? 'center_table' : 'conter_3'">
    <div
      class="list-btn fr"
      v-if="showListBtn && tableData && tableData.length > 0"
      @click="showMore"
    >
      {{ listText }}
    </div>
    <div
      class="delete fr"
      v-if="showDelete && itemsShowDelete"
      @click="deleteItems"
    >
      <i class="el-icon-delete"></i>
      <span>删除</span>
    </div>
    <div class="up-bottom-btns fr" v-if="showUpBottom">
      <div class="up delete fr" v-if="showUp" @click="toTop">
        <i class="el-icon-top"></i>
        <span>上移</span>
      </div>
      <div class="bottom delete fr" v-if="showBottom" @click="toBottom">
        <i class="el-icon-bottom"></i>
        <span>下移</span>
      </div>
    </div>
    <el-table
      ref="simpleTable"
      @sort-change="handleChangeSort"
      @select="handleSelect"
      @selection-change="handleSelectionChange"
      @row-click="handleRowClick"
      @current-change="handleCurrentChange"
      :row-class-name="tableRowClassName"
      :data="constantTableData"
      :row-key="getRowKeys"
      style="width: 100%; height: 100%"
      :max-height="maxHeight"
      stripe
      border
      class="common-table"
      :class="[listBtnClass, taskTableClass]"
    >
      <el-table-column
        v-if="hasSelect"
        type="selection"
        :reserve-selection="true"
        width="55"
      ></el-table-column>
      <el-table-column v-if="showChoose" width="40px" align="center">
        <template slot-scope="scope">
          <div
            class="choose-style"
            :class="chooseId === scope.row.id ? 'is-choose-style' : ''"
          >
            <div class="child" v-if="chooseId === scope.row.id"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!noSerial"
        type="index"
        :label="serialText"
        :width="serialWidth"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="initObj.page">{{
            (initObj.page - 1) * initObj.size + scope.$index + 1
          }}</span>
          <span :class="'serial' + (scope.$index + 1)" v-else>{{
            scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <!-- :show-overflow-tooltip="true" -->
      <el-table-column
        v-for="(item, index) in columnData"
        :key="item.prop"
        :prop="item.prop"
        :label="item.title"
        :align="item.align ? item.align : alignText ? alignText : ''"
        :width="item.width ? item.width : ''"
        :sortable="!item.notSortable"
        :render-header="
          item.tooltip ? renderHeaderMethods : notRenderHeaderMethods
        "
      >
        <template slot-scope="scope">
          <span v-if="item.special">
            <span
              class="tbTitle"
              :title="scope.row[item.prop]"
              @click="
                () => {
                  handleClickTitle(item, scope.row)
                }
              "
              v-html="
                item.options
                  ? scope.row[item.options[item.prop]] ||
                    scope.row[item.options[item.prop]] === 0
                    ? scope.row[item.options[item.prop]]
                    : '--'
                  : scope.row[item.prop] || scope.row[item.prop] === 0
                  ? scope.row[item.prop]
                  : '--'
              "
            ></span>
            <el-popover
              v-if="item.hasPop"
              placement="bottom"
              width="200"
              trigger="click"
              :content="popContentVal || ''"
            >
              <i
                style="margin-left: 3px; cursor: pointer; color: #0ba9e1"
                slot="reference"
                @click="
                  () => {
                    handleSearchPopContent(scope.row)
                  }
                "
                class="el-icon-s-operation"
              ></i>
            </el-popover>
          </span>
          <span v-else-if="item.edit">
            <el-input
              v-if="scope.row.isSet && scope.row.rowIndex === index"
              ref="editInput"
              @change="changeInput(content, item.prop, $event, scope.$index)"
              @blur="changeInputBlur"
              v-model="content"
              placeholder="请输入内容"
            ></el-input>
            <span
              v-else
              :title="scope.row[item.prop]"
              @click="
                changeInputContent(
                  scope.row[item.prop],
                  item.prop,
                  $event,
                  scope.$index,
                  scope
                )
              "
            >
              {{
                item.options
                  ? item.options[scope.row[item.prop]] ||
                    item.options[scope.row[item.prop]] === 0
                    ? item.options[scope.row[item.prop]]
                    : '--'
                  : scope.row[item.prop] || scope.row[item.prop] === 0
                  ? scope.row[item.prop]
                  : '--'
              }}
            </span>
          </span>
          <span
            :title="scope.row[item.prop]"
            v-else
            v-html="
              item.options
                ? item.options[scope.row[item.prop]] ||
                  item.options[scope.row[item.prop]] === 0
                  ? item.options[scope.row[item.prop]]
                  : '--'
                : scope.row[item.prop] || scope.row[item.prop] === 0
                ? scope.row[item.prop]
                : '--'
            "
          >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showHandle"
        :label="handleText"
        :width="handleWidth"
        align="center"
      >
        <template slot-scope="scope">
          <el-popover
            v-if="showPreview"
            placement="left"
            width="172"
            trigger="click"
            @show="handlePreviewURL(scope.row)"
          >
            <div class="btn_fx">
              <el-button size="yl" round @click="handlePreviewJump(scope.row)"
                >预览</el-button
              >
              <div class="btn_fxd">分享到</div>
              <div :id="'qrcode' + scope.row.id"></div>
            </div>
            <span slot="reference" style="cursor: pointer">预览</span>
          </el-popover>
          <span v-else class="browse-reports" @click="handleClick(scope.row)">{{
            handleContent
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="showPagination"
      :show-total="showTablePagination"
      :init-obj="initObj"
      @onChangePageData="handleChangePageData"
    />
  </div>
</template>
<script>
import Pagination from '../pagination'
import promptMessage from '../promptMessage/index'
export default {
  name: 'SimpleTable',
  components: {
    Pagination
  },
  computed: {
    itemsShowDelete() {
      return this.chooseList.length > 0 ? true : false
    },
    showUp() {
      return (this.contentData.number !== 0 ||
        (this.contentData.number === 0 && this.chooseCurrentIndex !== 0)) &&
        this.chooseList.length === 1
        ? true
        : false
    },
    showBottom() {
      return (this.contentData.number !== this.contentData.totalPages - 1 ||
        (this.contentData.number === this.contentData.totalPages - 1 &&
          this.chooseCurrentIndex !== this.tableData.length - 1)) &&
        this.chooseList.length === 1
        ? true
        : false
    },
    listBtnClass() {
      return this.showListBtn
        ? this.isShowMore
          ? 'auto-height'
          : 'fixed-height'
        : ''
    },
    taskTableClass() {
      return this.showListBtn ? 'taskTable' : ''
    },
    showPagination() {
      if (this.showListBtn) {
        return this.isShowMore && this.tableData.length > 0
      } else {
        return !this.noPagination && this.tableData.length > 0
      }
    }
  },
  props: {
    initObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    popContent: {
      type: String,
      default: ''
    },
    columnData: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSerial: {
      type: Boolean,
      default: false
    },
    serialText: {
      type: String,
      default: '序号'
    },
    uniqueIdName: {
      type: String,
      default: 'id'
    },
    maxHeight: {
      type: String,
      default: '1000'
    },
    chartTable: {
      type: Boolean,
      default: false
    },
    noPagination: {
      type: Boolean,
      default: false
    },
    hasSelect: {
      // 是否显示多个选择
      type: Boolean,
      default: false
    },
    showChoose: {
      // 是否显示单个选择
      type: Boolean,
      default: false
    },
    showDelete: {
      // 是否显示删除
      type: Boolean,
      default: false
    },
    showUpBottom: {
      // 是否显示上移下移按钮
      type: Boolean,
      default: false
    },
    contentData: {
      // 返回的数据
      type: Object,
      default: () => {
        return {}
      }
    },
    showHandle: {
      // 是否显示操作
      type: Boolean,
      default: false
    },
    showPreview: {
      // 是否显示预览
      type: Boolean,
      default: false
    },
    handleText: {
      // 操作表头信息
      type: String,
      default: '操作'
    },
    handleWidth: {
      // 操作表头信息宽度
      type: String,
      default: '100px'
    },
    handleContent: {
      // 操作内容信息
      type: String,
      default: ''
    },
    showListBtn: {
      // 是否显示任务列表
      type: Boolean,
      default: false
    },
    isClick: {
      // 是否可点击
      type: Boolean,
      default: false
    },
    showTablePagination: {
      // 是否显示分页total
      type: Boolean,
      default: true
    },
    alignText: {
      // 文字居左
      type: String,
      default: ''
    },
    serialWidth: {
      // 序号宽度
      type: String,
      default: '90px'
    },
    showCloseOpen: {
      // 列表展开收起
      type: Boolean,
      default: true
    },
    listText: {
      type: String,
      default: '刊期列表'
    }
  },
  watch: {
    popContent(nv) {
      this.popContentVal = nv
    },
    tableData(n) {
      if (n) {
        this.constantTableData = n
      }
    }
  },
  data() {
    return {
      popContentVal: '',
      getRowKeys(row) {
        return row.id
      },
      content: '',
      chooseList: [], // 当前选中文章列表
      chooseRow: '', // 选中单行数据
      chooseCurrentIndex: null, // 当前选中index
      isShowMore: false,
      chooseId: '',
      constantTableData: []
    }
  },
  mounted() {
    if (this.tableData) {
      this.constantTableData = this.tableData
    }
  },
  methods: {
    handleColumnData() {
      this.columnData.map((item) => {
        if (item.prop.indexOf('||') !== -1) {
          let propsArr = item.prop.split('||')
          propsArr.map((it) => {
            if (this.tableData.length > 0) {
              this.tableData.map((i, nx) => {
                if (i[it] && !i.fanalItem) {
                  this.$set(this.tableData[nx], 'fanalItem', i[it])
                }
              })
            }
          })
        }
      })
    },
    // 需要点击获取pop内容的
    handleSearchPopContent(chooseItem = {}) {
      this.$emit('onClickPopIcon', chooseItem)
    },
    // 需要点击操作的
    handleClickTitle(chooseColumnItem = {}, chooseItem) {
      this.$emit('onClickTitle', chooseItem, chooseColumnItem)
    },
    // 页码改变了之后的操作
    handleChangePageData(pageObj) {
      this.$emit('onChangePageData', pageObj)
    },
    // 排序字段改变
    handleChangeSort(val) {
      const { prop, order } = val
      this.$emit('onChangeSort', prop, order)
    },
    clearSort() {
      this.$refs.simpleTable.clearSort()
    },
    handleSelect() {},
    handleSelectionChange(val) {
      this.chooseList = val
      if (val.length === 1) {
        this.tableData.map((item, index) => {
          if (val[0].id === item.id) {
            this.chooseRow = item
            this.chooseCurrentIndex = index
          }
        })
      }
      this.$emit('onChangeSelection', val)
    },
    //切换为input框
    changeInputContent(val, prop, e, index) {
      this.tableData.map((item) => {
        this.$set(item, 'isSet', false)
        this.$set(item, 'rowIndex', null)
      })
      this.columnData.map((it, rowIndex) => {
        if (it.prop === prop) {
          this.$set(this.tableData[index], 'rowIndex', rowIndex)
        }
      })
      this.$set(this.tableData[index], 'isSet', true)
      this.$nextTick(() => {
        this.$refs.editInput[0].focus()
      })
      this.content = val
      this.$emit('onChangeInput', val, prop, e, index)
    },
    //input改变事件
    changeInput(val, prop, e, index) {
      let currentId = this.tableData[index].id
      this.$emit(
        'onChangeInputBlur',
        val,
        prop,
        e,
        index,
        this.content,
        currentId
      )
    },
    //input失焦事件
    changeInputBlur() {
      this.tableData.map((item) => {
        this.$set(item, 'isSet', false)
        this.$set(item, 'rowIndex', null)
      })
    },
    // 清空多选
    clearSelection() {
      this.$refs.simpleTable.clearSelection()
    },
    // 上移
    toTop() {
      this.$emit('onChangeToTop')
    },
    // 下移
    toBottom() {
      this.$emit('onChangeToBottom')
    },
    // 删除
    deleteItems() {
      this.$emit('onChangeDeleteItems')
    },
    // 点击操作
    handleClick(item) {
      this.$emit('onHandleClick', item)
    },
    // 推送结果选中行改变
    handleCurrentChange(val) {
      // 选中行
      if (val) {
        // 设置radio选中
        this.$emit('onChangeRow', val)
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex
    },
    // 点击每一行
    handleRowClick(row) {
      if (this.isClick) {
        this.chooseList = []
        this.chooseList.push(row)
        this.chooseId = row.id
        this.tableData.map((item, index) => {
          if (row.id === item.id) {
            this.chooseCurrentIndex = index
          }
        })
        this.$emit('onRowClick', this.chooseId, row)
        if (this.showCloseOpen) {
          if (this.isShowMore) {
            this.showMore()
          } else {
            this.constantTableData = this.chooseList
          }
        }
      }
    },
    showMore() {
      if (this.isShowMore) {
        this.isShowMore = false
        this.constantTableData = this.chooseList
      } else {
        this.isShowMore = true
        this.constantTableData = this.tableData
      }
    },
    // 表格预览
    handlePreviewURL(item) {
      this.$emit('onHandlePreviewURL', item)
    },
    handlePreviewJump(item) {
      this.$emit('onHandlePreviewJump', item)
    },
    notRenderHeaderMethods(h, { column }) {
      return column.label
    },
    renderHeaderMethods(h, { column, $index }) {
      let _this = this
      return h(
        'div',
        {
          style:
            'display:flex;margin:auto;justify-content:center;position:relative;'
        },
        [
          h('span', column.label),
          h(promptMessage, {
            // 引用 promptMessages 组件
            props: {
              messages: [_this.columnData[$index - 1].tooltipContent]
            }
          })
        ]
      )
    }
  }
}
</script>
<style lang="scss">
.el-tooltip__popper {
  font-size: 14px;
  max-width: 50%;
}
.el-table .cell {
  padding-right: 0;
}
.fixed-height {
  .el-table td.gutter {
    display: none;
  }
}
.taskTable .el-table__empty-block {
  min-height: 34px;
}
.el-popover .btn_fx {
  img {
    min-width: auto;
  }
  .el-button.is-round {
    padding: 7px 20px !important;
  }
}
.el-table__empty-block {
  min-height: 34px;
}
</style>
<style lang="scss" scoped>
.clickUrl {
  cursor: pointer;
}
.choose-style {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
  box-sizing: initial;
  position: relative;
  cursor: pointer;
  display: block;
  margin: auto;
  // margin-top: 10px;
  .child {
    border-radius: 50%;
    background: white;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 33%;
    left: 36%;
    box-sizing: initial;
  }
}
.choose-style:hover {
  border: 1px solid #2644ca;
}
.is-choose-style {
  width: 17px;
  height: 17px;
  background: #2644ca;
}
.delete {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 20px;
  align-items: center;
  width: 70px;
  height: 30px;
  justify-content: center;
  margin: 0 10px 10px 0;
  cursor: pointer;
  color: #91949d;
  span {
    margin-left: 4px;
  }
  &:hover {
    color: white;
    background-color: #265cca;
  }
}
.list-btn {
  font-size: 14px;
  // padding-right: 10px;
  cursor: pointer;
  padding-left: 26px;
  display: inline-block;
  color: #2644ca;
  line-height: 54px;
  background: url('../../assets/img/icon_btn_list.png') left center no-repeat;
}
.browse-reports {
  cursor: pointer;
  color: #2644ca;
}
.auto-height {
  height: auto;
  // padding-right: 10px;
}
.fixed-height {
  // padding-right: 10px;
  // height: 80px !important;
}
.btn_fx {
  padding: 14px 26px;
  text-align: center;
}
.btn_fxd {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0;
}
.btn_fx2 {
  text-align: center;
}
.tbTitle {
  cursor: pointer;
}
</style>
