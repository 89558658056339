<template>
  <div class="tooltip">
    <el-tooltip effect="dark" placement="top">
      <div slot="content">
        <!-- 插槽，可提供多行的提示信息 -->
        <p v-for="item in messages" :key="item">
          {{ item }}
        </p>
      </div>
      <i
        class="el-icon-question"
        style="
          color: #333333;
          position: absolute;
          top: 6px;
          cursor: pointer;
          right: -8px;
        "
      ></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
<style scoped>
.tooltip {
  position: relative;
}
</style>
